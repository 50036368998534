import { Skeleton } from 'antd';

export function  Marketingloading() {
  return (
    <>
     
      <div className="flex flex-col p-2">
      <Skeleton active avatar>
              
            </Skeleton>
      </div>


    </>
  );
}

export default Marketingloading