import { PageHeader } from 'antd'
import React from 'react'

export default function HomePage() {
  return (
    <div>
      <PageHeader>Page Not Found</PageHeader>
      <h1>
     
      </h1>
      
    </div>
  )
}
