import {
  DatePicker,
  Checkbox,
  Form,
  Input,
  Select,
  Typography,
  Tabs,
  Row,
  Col,
  PageHeader,
  InputNumber,
  Space,
  Button,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import PhoneNumberPrefix from "../../../shared/user-phone-number-prefix";
import { httpService } from "../../../service/http.service";
import { lookupEndPoint } from "../../../api/primecareApi.endpoint";
import "./manage-crop.css";
import TextArea from "antd/lib/input/TextArea";

const CropManageForm = ({ form, isEditMode, modalConfig }) => {
  const { TabPane } = Tabs;
  console.log(modalConfig);
  const [cropTypes, setCropTypes] = useState([]);
  const [cropTypes$, setCropTypes$] = useState([]);

  const [varieties, setVarieties] = useState([]);
  const [varieties$, setVarieties$] = useState([]);

  const [organizations, setOrganizations] = useState([]);
  const [organizations$, setOrganizations$] = useState([]);

  const [highlands, setHighlands] = useState([]);
  const [highlands$, setHighlands$] = useState([]);

  const [soileTypes, setSoilTypes] = useState([]);
  const [soileTypes$, setSoilTypes$] = useState([]);

  const [insects, setInsects] = useState([]);
  const [insects$, setInsects$] = useState([]);

  const [diseases, setDiseases] = useState([]);
  const [diseases$, setDiseases$] = useState([]);

  const [growthHabits, setGrowthHabits] = useState([]);
  const [growthHabits$, setGrowthHabits$] = useState([]);

  const [rowTypes, setRowTypes] = useState([]);
  const [rowTypes$, setRowTypes$] = useState([]);

  const [panicleForms, setPanicleForms] = useState([]);
  const [panicleForms$, setPanicleForms$] = useState([]);

  const [maturityGroups, setMaturityGroups] = useState([]);
  const [maturityGroups$, setMaturityGroups$] = useState([]);

  const [colors, setColors] = useState([]);
  const [colors$, setColors$] = useState([]);
  
  const [isCropTypeFetched, setIsCropTypeFetched] = useState(false);
  const [isVarietyFetched, setIsVarietyFetched] = useState(false);
  const [isOrganizationFetched, setIsOrganizationFetched] = useState(false);
  const [isHighlandFetched, setIsHighlandFetched] = useState(false);
  const [isSoilTypeFetched, setIsSoilTypeFetched] = useState(false);

  const [cropTypeId, setCropTypeId] = useState();

  useEffect(() => {
    getCropTypes();
    getOrganizations();
    getHighlands();
    getSoilTypes();
    getInsects();
    getDiseases();
    getGrowthHabits();
    getPanicleForms();
    getRowTypes();
    getMaturityGroups();
    getColors();
  }, []);

  useEffect(() => {
    debugger
    if(isEditMode) {    
        setCropTypeId(modalConfig?.cropTypeId)
          getVarieties();
    }
  }, [modalConfig]);

  useEffect(() => {    
    getVarieties();   
  }, [cropTypeId]);

  const getColors = () => {
    httpService
      .get(lookupEndPoint.getColors)
      .then((response) => {
        console.log(response.data);
        setColors(response.data);
        setColors$(response.data);
      })
      .catch(() => {
     
      });
  };
  const getMaturityGroups = () => {
    httpService
      .get(lookupEndPoint.getMaturityGroups)
      .then((response) => {
        console.log(response.data);
        setMaturityGroups(response.data);
        setMaturityGroups$(response.data);
      })
      .catch(() => {
     
      });
  };

  const getRowTypes = () => {
    httpService
      .get(lookupEndPoint.getRowTypes)
      .then((response) => {
        console.log(response.data);
        setRowTypes(response.data);
        setRowTypes$(response.data);
      })
      .catch(() => {
     
      });
  };

  const getPanicleForms = () => {
    httpService
      .get(lookupEndPoint.getPanicleForms)
      .then((response) => {
        console.log(response.data);
        setPanicleForms(response.data);
        setPanicleForms$(response.data);
      })
      .catch(() => {
     
      });
  };
  const getGrowthHabits = () => {
    httpService
      .get(lookupEndPoint.getGrowthHabits)
      .then((response) => {
        console.log(response.data);
        setGrowthHabits(response.data);
        setGrowthHabits$(response.data);
      })
      .catch(() => {
     
      });
  };

  const getDiseases = () => {
    httpService
      .get(lookupEndPoint.getDiseases)
      .then((response) => {
        console.log(response.data);
        setDiseases(response.data);
        setDiseases$(response.data);
      })
      .catch(() => {
     
      });
  };
  const getInsects = () => {
    httpService
      .get(lookupEndPoint.getInsects)
      .then((response) => {
        console.log(response.data);
        setInsects(response.data);
        setInsects$(response.data);
        
      })
      .catch(() => {
       
      });
  };

  const getSoilTypes = () => {
    httpService
      .get(lookupEndPoint.getSoilTypes)
      .then((response) => {
        console.log(response.data);
        setSoilTypes(response.data);
        setSoilTypes$(response.data);
        setIsSoilTypeFetched(true);
      })
      .catch(() => {
        setIsSoilTypeFetched(false);
      });
  };
  const getVarieties = () => {   
    
    httpService
      .get(lookupEndPoint.getVarieties)
      .then((response) => {        
        setVarieties(response.data?.filter(x=>x?.cropTypeId===cropTypeId));       
        setIsVarietyFetched(true);
      })
      .catch(() => {
        setIsVarietyFetched(false);
      });
  };
  const getHighlands = () => {
    httpService
      .get(lookupEndPoint.getHighlands)
      .then((response) => {
        console.log(response.data);
        setHighlands(response.data);
        setHighlands$(response.data);
        setIsHighlandFetched(true);
      })
      .catch(() => {
        setIsHighlandFetched(false);
      });
  };

  const getCropTypes = () => {
    httpService
      .get(lookupEndPoint.getCropTypes)
      .then((response) => {
        console.log(response.data);
        setCropTypes(response.data);
        setCropTypes$(response.data);
        setIsCropTypeFetched(true);
        console.log(cropTypes);
      })
      .catch(() => {
        setIsCropTypeFetched(false);
        setIsOrganizationFetched(false);
      });
  };
  const getOrganizations = () => {
    httpService
      .get(lookupEndPoint.getOrganizations)
      .then((response) => {
        setOrganizations(response.data);
        setOrganizations$(response.data);
        setIsOrganizationFetched(true);
      })
      .catch(() => {
        setIsCropTypeFetched(false);
        setIsOrganizationFetched(false);
      });
  };

  const diseaseInputs = diseases.map((item:any) => {
    return {
      name: item.name,
      diseaseId: item.diseaseId,
      value: "-"
    };
  });
  const insectInputs = insects.map((item:any) => {
    return {
      name: item.name,
      insectId: item.insectId,
      value: "-"
    };
  });

const diseasesReactions=[
  {name:'Resistant'},
  {name:'Moderately Resistant'},
  {name:'Susceptible'},
];

const diseasesCauses=[
  {name:'Fungal'},
  {name:'Bacterial'},
  {name:'Viral'},
];

const OPVOrHybrid=[
  {name:'OPV'},
  {name:'Hybrid'},
];


const yesNoOptions=[
  {name:'Yes'},
  {name:'No'},
];


  return (
    <Form
      form={form}
      layout={"horizontal"}
      preserve={false}
      size="small"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 30 }}
    >
      
      <Tabs defaultActiveKey="1" className="p-3">     

        <TabPane tab="General" key={"1"} forceRender>
          <div className="grid md:grid-cols-2">
            <div className={"md:border-r-2 md:pr-8 lg:pr-6"}>
              <Form.Item
                name={["cropTypeId"]}
                label={"Plant"}
                rules={[{ required: true, message: "select Crop" }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && cropTypes.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  
                  options={cropTypes?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.cropTypeId,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                  onChange={(cropTypeId) => {
                    setCropTypeId(cropTypeId);
                  }}
                />
              </Form.Item>

              <Form.Item
                name={["varietyId"]}
                label={"Variety"}
                rules={[{ required: true, message: "select Variety" }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && varieties.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={varieties?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.varietyId,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>
              <Form.Item
                name={["opvorHybrid"]}
                label={"OPV/Hybrid"}
                rules={[{ required: true, message: "select OPV/Hybrid" }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && varieties.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={OPVOrHybrid?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.name,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>
              <Form.Item
                label="Release Year"
                name="releaseYear"
                rules={[
                  { required: true, message: "Please select release Year" },
                ]}
              >
                <DatePicker
                 
                 className={'w-full'}
                 popupStyle={{width:'31%'}}
                 dropdownClassName={'w-auto'}                         
                 picker={'year'}
                 suffixIcon={<Icon fontSize={25} icon="bx:chevron-down" />}                 
                 
                />
              </Form.Item>
          
              <Form.Item
                name={["maintainerId"]}
                label={"Maintainer"}
                rules={[
                  { required: true, message: "Select Maintainers" },
                ]}
                hidden={isEditMode && organizations.length === 0}
              >
                <Select                 
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={organizations?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.organizationId,
                      title: _.name,
                      label: _.name,
                    };
                  })}
                ></Select>
              </Form.Item>

            </div>
     
          </div>
        </TabPane>
        <TabPane tab="Adaptation, Seed Rate,and Fertilizer" key={"2"} forceRender>
          <div className="grid md:grid-cols-2">
            <div className={"md:border-r-2 md:pr-8 lg:pr-6"}>
              <PageHeader title="Adaptation" />

              <Form.Item label="Altitude" name="adtAltitude">
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
                name={["highlandId"]}
                label={"Climate Zone"}
               // rules={[{ required: true, message: "select Highland" }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && highlands.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={highlands?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.highlandId,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>
            
             
              <Form.Item label="Rainfall" name="adtRainfall">
                <Input placeholder="" />
              </Form.Item>

              <Form.Item
              label="Adaptation Area"
              name="adtAdaptationArea"
              >
           <TextArea rows={2}></TextArea>
              </Form.Item>
              
                 <Form.Item
                name={["adtMoistureStressArea"]}
                label={"Moisture Stress Area"}
              //  rules={[{ required: true, message: "select Moisture Stress Area" }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                 
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={yesNoOptions?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.name,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>
           
           
              <Form.Item
                name={["adtSuitableToIrrigation"]}
                label={"Suitable to Irrigation"}
                //rules={[{ required: true, message: "select Suitable to Irrigation" }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                 
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={yesNoOptions?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.name,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>
          
              <Form.Item
                name={["adtSuitableToInterCropping"]}
                label={"Suitable to Inter Cropping"}
               // rules={[{ required: true, message: "select Suitable to Inter Cropping" }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                 
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={yesNoOptions?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.name,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>
            
              <Form.Item
                name={["adtSuitableToSoleCropping"]}
                label={"Suitable to Sole Cropping"}
                //rules={[{ required: true, message: "select Suitable to Sole Cropping" }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                 
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={yesNoOptions?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.name,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>

              <Form.Item
                name={["soilTypeId"]}
                label={"Soil"}
                //rules={[{ required: true, message: "select Soil" }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && soileTypes.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={soileTypes?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.soilTypeId,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>
            
              <PageHeader title="Seed Rate By Planting Method (kg/ha)" />
              <Form.Item label="Broadcast" name="srBroadcast">
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label="Drill" name="srDrill">
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label="Row" name="srRow">
                <Input placeholder="" />
              </Form.Item>
            </div>
            <div className={"md:border-r-2 md:pr-8 lg:pr-6"}>           

            
              
              <PageHeader title="Fertilizer Type , Rate and Time" />
              <Form.Item label="Nitrogen" name="agrFertilizerNitrogenOrUrea">
                <TextArea placeholder="" rows={2} />
              </Form.Item>
              <Form.Item label="P2O5" name="agrFertilizerP2o5">
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label="Boron" name="agrFertilizerBoron">
                <Input placeholder="" />
              </Form.Item>

              <Form.Item label="Zink" name="agrFertilizerZink">
                <Input placeholder="" />
              </Form.Item>

              <Form.Item label="Sulfur" name="agrFertilizerSulfer">
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label="Copper" name="agrFertilizerCopper">
                <Input placeholder="" />
              </Form.Item>

              <Form.Item label="Others" name="agrFertilizerOther">
                <TextArea rows={2} placeholder="" />
              </Form.Item>
</div>

          </div>
        </TabPane>
        <TabPane tab="Reaction to Diseases, Insects" key={"3"} forceRender>
            <div className="grid md:grid-cols-2">
            <div className={"md:border-r-2 md:pr-8 lg:pr-6"}>
          <PageHeader title="Reaction to Diseases" />

          <Form.List name="diseases">
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
         
                 <Space key={field.key}  align="baseline">
                    <Form.Item
                      {...field}
                      //label="Disease"
                      name={[field.name, 'diseaseId']}
                      rules={[{ required: true, message: 'Missing Disease' }]}
                    >                     
                      <Select
                   style={{ minWidth: 150 }}
                  showSearch={true}
                  placeholder="Disease"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={diseases?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.diseaseId,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />


                    </Form.Item>                
                
                    <Form.Item
                      {...field}
                    //  label="Reaction"
                      name={[field.name, 'value']}
                      rules={[{ required: true, message: 'Missing Reactions' }]}
                    >                     
                      <Select
                   style={{ minWidth: 150 }}
                  showSearch={true}
                  placeholder="Disease Reaction"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={diseasesReactions?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.name,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
               </Form.Item>
                  <Form.Item
                      {...field}
                    //  label="Reaction"
                      name={[field.name, 'cause']}
                      rules={[{ required: true, message: 'Disease Cause' }]}
                    >                     
                      <Select
                   style={{ minWidth: 150 }}
                  showSearch={true}
                  placeholder="Disease Cause"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={diseasesCauses?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.name,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />


                    </Form.Item>    
                   

                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Diseases Reactions
              </Button>
            </Form.Item>
          </>
        )}
          </Form.List>
    
          </div>
          < div className={"md:border-r-2 md:pr-8 lg:pr-6"}>
        

          <PageHeader title="Reaction to Insects" />
    
    <Form.List name="insects">
    {(fields, { add, remove }) => (
      <>
        {fields.map(field => (
          <Space key={field.key} align="baseline">                
                <Form.Item
                  {...field}
                  //label="Insects"
                  name={[field.name, 'insectId']}
                  rules={[{ required: true, message: 'Missing Insects' }]}
                >                     
                  <Select
             size="small"
             style={{ minWidth: 150 }}  
              showSearch={true}
              placeholder="Insects"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              options={insects?.map((_: any, index) => {
                return {
                  key: index,
                  value: _.insectId,
                  label: _.name,
                  title: _.name,
                };
              })}
            />


                </Form.Item>                
            
                <Form.Item
                  {...field}
                //  label="Reaction"
                  name={[field.name, 'value']}
                  rules={[{ required: true, message: 'Missing Reactions' }]}
                >                     
                  <Select
             size="small"
             style={{ minWidth: 150 }}  
              showSearch={true}
              placeholder="Reaction to Insects"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              options={diseasesReactions?.map((_: any, index) => {
                return {
                  key: index,
                  value: _.name,
                  label: _.name,
                  title: _.name,
                };
              })}
            />


                </Form.Item>    
                <Form.Item
                        {...field}
                        name={[field.name, "cause"]}
                        
                      //  rules={[{ required: true, message: "Missing Price" }]}
                      >
                        <TextArea
                         placeholder="Remark"
                          size="small"
                          style={{ minWidth: 150 }}  
                          autoSize     
                          rows={1}                 
                        />
                      </Form.Item>

            <MinusCircleOutlined onClick={() => remove(field.name)} />
          </Space>
        ))}

        <Form.Item>
          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
            Add Insects Reactions
          </Button>
        </Form.Item>
      </>
    )}
      </Form.List>
            </div>
           
          </div>
          <PageHeader title="Reaction to Weeds" />
            <Form.Item  name="reactionToWeed">
             <TextArea placeholder="" />
              </Form.Item> 
        </TabPane>

        <TabPane tab="Agronomics and Morphological characteristics" key={"4"} forceRender>
        <div className="grid md:grid-cols-2">
            <div className={"md:border-r-2 md:pr-8 lg:pr-6"}>
            <PageHeader title="Agronomic Requirements" />

            <Form.Item label="Spacing Between Row" name="agrSpacingBetweenRow">
              <Input placeholder="" />
            </Form.Item>

            <Form.Item label="Spacing Between Plant" name="agrSpcingBetweenPlant">
              <TextArea placeholder="" rows={2}/>
            </Form.Item>
            <Form.Item label="Suitable planting period" name="agrPlantingDateRangeOfMonth">
              <TextArea rows={2} placeholder="" />
            </Form.Item>
            <PageHeader title="Morphological characteristics" />
          <Form.Item label="Plant Height(cm)" name="mrphoPlantHeight">
             <InputNumber placeholder="" />
              </Form.Item>    
              <Form.Item label="Stem Pigmentation" name="mrphoStemPigmentation">
             <Input placeholder="" />
              </Form.Item>    
              <Form.Item label="Days to Heading" name="mrphoDaystoHeading">
             <Input placeholder="" />
              </Form.Item>    
              <Form.Item label="Days to Maturity" name="mrphoDaystoMaturity">
             <Input placeholder="" />
              </Form.Item>    
              <Form.Item label="Panicle Length" name="mrphoPanicleLength">
             <Input placeholder="" />
              </Form.Item>    
              <Form.Item label="Spike Length" name="mrphoSpikeLength">
             <Input placeholder="" />
              </Form.Item>    
              <Form.Item label="Ear/CobLength" name="mrphoEarOrCobLength">
             <Input placeholder="" />
             </Form.Item>    
              <Form.Item label="Leaf Arrangement" name="mrphoLeafArrangement">
             <TextArea rows={2} placeholder="" />
              </Form.Item>
             <Form.Item label="Anther Color" name="mrphoAnterColor">
             <Input placeholder="" />

             </Form.Item> 


          </div>
          <div className={" md:pl-8 lg:pl-16"}>
              <Form.Item
                name={["growthHabitId"]}
                label={"Growth Habit"}
                // rules={[{ required: true, message: 'select CropType' }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && cropTypes.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={growthHabits?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.growthHabitId,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>

              <Form.Item
                name={["panicleFormId"]}
                label={"Panicle Form"}
                // rules={[{ required: true, message: 'select CropType' }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && cropTypes.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={panicleForms?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.panicleFormId,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>
              <Form.Item
                name={["rowTypeId"]}
                label={"Row Types"}
                // rules={[{ required: true, message: 'select CropType' }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && cropTypes.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={rowTypes?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.rowTypeId,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>
              <Form.Item
                name={["maturityGroupId"]}
                label={"Maturity Groups"}
                // rules={[{ required: true, message: 'select CropType' }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && cropTypes.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={maturityGroups?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.maturityGroupId,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>

              <Form.Item
                name={["flowerColor"]}
                label={"Flower Color"}
                // rules={[{ required: true, message: 'select CropType' }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && cropTypes.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={colors?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.colorName,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>

              <Form.Item
                name={["seedColor"]}
                label={"Seed Color"}
                // rules={[{ required: true, message: 'select CropType' }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && cropTypes.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={colors?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.colorName,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>

              <Form.Item
                name={["seedCoatColor"]}
                label={"Seed Coat Color"}
                // rules={[{ required: true, message: 'select CropType' }]}
                //  hidden={!isCropTypeFetched}
              >
                <Select
                  disabled={!isEditMode && cropTypes.length === 1}
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={colors?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.colorName,
                      label: _.name,
                      title: _.name,
                    };
                  })}
                />
              </Form.Item>

            </div>
          </div>
        </TabPane>
        <TabPane tab="Quality Attributes and  Yields" key={"5"} forceRender>
        <div className="grid md:grid-cols-2">
           
          < div className={"md:border-r-2 md:pr-8 lg:pr-6"}>
          <PageHeader title="Quality attributes" />
             <Form.Item label="Oil content(%)" name="qualityOilcontent">
             <InputNumber placeholder="" min="0" max="100"  />
              </Form.Item>    
              <Form.Item label="Protein Content(%)" name="qualityProteinContent">
              <InputNumber placeholder="" min="0" max="100"  />
              </Form.Item>    
              <Form.Item label="Glutien Content(%)" name="qualityGlutienContent">
              <InputNumber placeholder="" min="0" max="100"  />
              </Form.Item>    
              <Form.Item label="Extract(%)" name="qualityExtract">
              <InputNumber placeholder="" min="0" max="100"  />
              </Form.Item>    
              <Form.Item label="HLW(kg/hl)" name="qualityHlw">
              <InputNumber placeholder="" min="0"   />
              </Form.Item>    
              <Form.Item label="Seed Size" name="qualityGrainSeedSize">
              <TextArea rows={1}/>
              </Form.Item>    
              <Form.Item label="Thousand Seed Weight(g)" name="qualityThousandSeedWeight">
              <InputNumber placeholder="" min="0"  />
              </Form.Item>    
              <Form.Item label="Soacability(%)" name="qualitySokability">
              <InputNumber placeholder="" min="0" max="100"  />
              </Form.Item>  
        
            </div>
            <div className={"md:border-r-2 md:pr-8 lg:pr-6"}>
            <PageHeader title="Grain Yield (qt/ha)" />
            <Form.Item label="Research Field" name="gyieldResearchField">
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label="Farmer's Field" name="syieldFarmersField">
                <Input placeholder="" />
              </Form.Item>
                  
            </div>
          </div>
        </TabPane>
        <TabPane tab="Seed Source" key={"6"} forceRender>
          <div className="grid md:grid-cols-2">
         
            <div className={" md:pl-8 lg:pl-16"}>
            <PageHeader title="Seed Source(Annually Update)"></PageHeader>
            <Form.Item
                name={["sourceOfBreederSeedIds"]}
                label={"Breeder seeds"}
                rules={[
                  { required: true, message: "select Source of breeder seeds" },
                ]}
                hidden={isEditMode && organizations.length === 0}
              >
                <Select
                  mode="multiple"
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={organizations?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.organizationId,
                      title: _.name,
                      label: _.name,
                    };
                  })}
                ></Select>
              </Form.Item>
              <Form.Item
                name={["sourceOfPreBasicSeedIds"]}
                label={"Pre-Basic Seed"}
                rules={[
                  { required: true, message: "Source of Pre-Basic Seed" },
                ]}
                hidden={isEditMode && organizations.length === 0}
              >
                <Select
                  mode="multiple"
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={organizations?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.organizationId,
                      title: _.name,
                      label: _.name,
                    };
                  })}
                ></Select>
              </Form.Item>
              <Form.Item
                name={["sourceOfBasicSeedIds"]}
                label={"Basic Seeds"}
                rules={[{ required: true, message: "Source of Basic Seeds" }]}
                hidden={isEditMode && organizations.length === 0}
              >
                <Select
                  mode="multiple"
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={organizations?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.organizationId,
                      title: _.name,
                      label: _.name,
                    };
                  })}
                ></Select>
              </Form.Item>
              <Form.Item
                name={["sourceOfCertifiedSeedIds"]}
                label={"Certified Seeds"}
                rules={[{ required: true, message: "Source of Certified Seeds" }]}
                hidden={isEditMode && organizations.length === 0}
              >
                <Select
                  mode="multiple"
                  showSearch={true}
                  placeholder=""
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.title?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  options={organizations?.map((_: any, index) => {
                    return {
                      key: index,
                      value: _.organizationId,
                      title: _.name,
                      label: _.name,
                    };
                  })}
                ></Select>
              </Form.Item>
           

            </div>
          </div>
        </TabPane>
      </Tabs>
    </Form>
  );
};

export default CropManageForm;
